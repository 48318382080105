import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Sidebar() {
  return (

    <div className="bg-art-beige ">


      <div className="flex flex-col flex-grow bg-white rounded-3xl text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8 border border-gray-300">
        <h2 className="text-2xl font-extrabold text-art-marron-fonce sm:text-3xl">
          <span className="block">Nos engagements</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-art-marron-fonce">
          Un déménagement, c’est un nouveau départ. Nous sommes déménageurs près de Lyon depuis plus de 25 ans. En travaillant avec nous, vous profitez d’un service serein et de déménageurs sérieux et expérimentés.
        </p>
        <div className="">
          <div className="mt-4">
            <div className="text-center">
              <StaticImage
                className="object-cover object-center "
                src="../../images/icon-shipping-simple.svg"
                height={100}
              />
              <span className="block align-middle text-lg font-bold text-art-marron-fonce">Savoir-faire depuis 25 ans</span>
            </div>
            {/* <div className="text-left h-30 sm:mt-0 sm:ml-6 lg:ml-0 col-span-2">
                                
                              </div> */}
          </div>
          <div className="mt-4">
            <div className="text-center">
              <StaticImage
                className="object-cover object-center"
                src="../../images/icon-warranty-simple.svg"
                height={100}
              />
              <span className="block align-middle text-lg font-bold text-art-marron-fonce">Devis gratuit</span>
            </div>
            <div className="text-left h-30 sm:mt-0 sm:ml-6 lg:ml-0 col-span-2">

            </div>
          </div>
          <div className="mt-4">
            <div className="text-center">
              <StaticImage
                className="object-cover object-center"
                src="../../images/icon-exchange-simple.svg"
                height={100}
              />
              <span className="block align-middle text-lg font-bold text-art-marron-fonce">Formule adaptée à vos besoins</span>
            </div>
            {/* <div className="text-left h-30 sm:mt-0 sm:ml-6 lg:ml-0 col-span-2">
                                
                              </div> */}
          </div>

        </div>
        <a
          href="/contact/"
          className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-lg font-medium rounded-md text-art-beige bg-art-bleu hover:bg-art-bleu sm:w-auto"
        >
          Demandez un devis
        </a>
      </div>




    </div>



  )
}
